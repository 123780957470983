import { useLocale } from '@engined/client/contexts/LocaleContext.js';
import { BUTTON_BACK, BUTTON_SAVE } from '@engined/client/locales.js';
import { LoadingButton } from '@mui/lab';
import { Box, Button, ButtonProps, styled } from '@mui/material';
import { SxProps } from '@mui/system';
import { To } from 'history';
import React from 'react';
import { useFormState } from 'react-hook-form';
import { Link as RouterLink, LinkProps as RouterLinkProps } from 'react-router-dom';

const Root = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(3),
  marginBottom: theme.spacing(2),
}));

Root.displayName = 'Root';

interface OwnProps {
  sx?: SxProps;
  isSubmitting?: boolean;
  submitLabel?: React.ReactNode;
  submitOnClick?: ButtonProps['onClick'];
  children?: React.ReactNode;
}

interface NoBackProps extends OwnProps {
  backLabel?: never;
  backTo?: never;
  backOnClick?: never;
  noBack: true;
}

interface BackProps extends OwnProps {
  backLabel?: React.ReactNode;
  backTo: To;
  backOnClick?: RouterLinkProps['onClick'];
  noBack?: false;
}

type Props = NoBackProps | BackProps;

const SubmitButtons: React.FunctionComponent<Props> = ({
  sx,
  isSubmitting,
  submitLabel,
  submitOnClick,
  backLabel,
  backTo,
  backOnClick,
  noBack = false,
  children,
}) => {
  const { t } = useLocale();

  return (
    <Root sx={sx}>
      <LoadingButton
        type="submit"
        variant="contained"
        color="primary"
        disabled={isSubmitting}
        onClick={submitOnClick}
        size="medium"
        sx={noBack && !children ? { width: { md: 'auto', xs: '100%' } } : undefined}
        loading={isSubmitting}
      >
        {submitLabel ?? t(BUTTON_SAVE)}
      </LoadingButton>{' '}
      {children}{' '}
      {!noBack && (
        <Button
          component={RouterLink}
          type="button"
          to={backTo!}
          onClick={backOnClick}
          size="medium"
          disabled={isSubmitting}
        >
          {backLabel ?? t(BUTTON_BACK)}
        </Button>
      )}
    </Root>
  );
};

SubmitButtons.displayName = 'SubmitButtons';

export default SubmitButtons;

// TODO: Swap to default when converted all components
export const ConnectedSubmitButtons: React.FC<Omit<NoBackProps, 'isSubmitting'> | Omit<BackProps, 'isSubmitting'>> = (
  props,
) => {
  const { isSubmitting } = useFormState();
  return <SubmitButtons isSubmitting={isSubmitting} {...props} />;
};
